export default {
  quotes: {
    loading: true,
    list: [],
    paginateInfo: {
      count: 0,
      currentPage: 0,
      firstItem: 0,
      hasMorePages: false,
      lastItem: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
    },
    noResults: false,
    sort: {
      sortBy: '',
      sortDir: '',
      sortCount: 0,
    },
    tableColumns: [],
    lenderList: [],
    quoteStatusesCount: {
      quoted_count: 0,
      in_progress_count: 0,
      funded_count: 0,
      expired_count: 0,
      declined_count: 0,
    },
  },
}
