import { jsonToGraphQLQuery, EnumType } from "json-to-graphql-query"
import Api from "@/services/Api"

const URL_API = "graphql"

interface IGetQuoteRequest {
  query: {
    brokerGetQuotes: {
      __args: {
        perPage: number,
        page: number,
        status?: EnumType,
        sortBy?: EnumType,
        sortDir?: EnumType,
        filter?: [],
        dealId?: number,
      },
      items: {
        id: boolean,
        deal_id: boolean,
        lender_id: boolean,
        address: boolean,
        lender_name: boolean,
        company_name: boolean,
        status: boolean,
        is_premium: boolean,
        rate_term: boolean,
        property_type: boolean,
        loan_programs: boolean,
        offer_amount: boolean,
        expire_at: boolean,
        interest_rate: boolean,
        origination_fee: boolean,
        capabilities_meta: {
          can_set_accept_status: boolean,
          can_set_reject_status: boolean,
          can_set_funded_status: boolean,
          can_open_message: boolean,
          has_deal_accepted_quote: boolean,
          can_set_committed_status: boolean,
          can_set_term_sheet_status: boolean,
          can_set_in_underwriting_status: boolean,
          can_set_disable_reject_status: boolean,
          has_broker_user_premium: boolean,
        },
      },
      paginateInfo: {
        count: boolean,
        currentPage: boolean,
        firstItem: boolean,
        hasMorePages: boolean,
        lastItem: boolean,
        lastPage: boolean,
        perPage: boolean,
        total: boolean,
      },
      tableColumns: boolean,
      lenderList: boolean,
    },
    quoteStatusesCount: {
      quoted_count: boolean,
      accepted_count: boolean,
      funded_count: boolean,
      expired_count: boolean,
      declined_count: boolean,
      all_count: boolean,
    },
  }
}

export default {

  async getQuoteStatusesCount() {
    const objectForm = {
      query: {
        quoteStatusesCount: {
          quoted_count: true,
          accepted_count: true,
          funded_count: true,
          expired_count: true,
          declined_count: true,
          all_count: true,
        },
      },
    }

    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async brokerGetQuotes(obj) {
    const objectForm: IGetQuoteRequest = {
      query: {
        brokerGetQuotes: {
          __args: {
            perPage: obj.perPage, 
            page: obj.page,
          },
          items: {
            id: true,
            deal_id: true,
            lender_id: true,
            address: true,
            lender_name: true,
            company_name: true,
            status: true,
            is_premium: true,
            rate_term: true,
            property_type: true,
            loan_programs: true,
            offer_amount: true,
            expire_at: true,
            interest_rate: true,
            origination_fee: true,
            capabilities_meta: {
              has_broker_user_premium: true,
              can_set_accept_status: true,
              can_set_reject_status: true,
              can_set_funded_status: true,
              can_open_message: true,
              has_deal_accepted_quote: true,
              can_set_committed_status: true,
              can_set_term_sheet_status: true,
              can_set_in_underwriting_status: true,
              can_set_disable_reject_status: true,
            },
          },
          paginateInfo: {
            count: true,
            currentPage: true,
            firstItem: true,
            hasMorePages: true,
            lastItem: true,
            lastPage: true,
            perPage: true,
            total: true,
          },
          tableColumns: true,
          lenderList: true,
        },
        quoteStatusesCount: {
          quoted_count: true,
          accepted_count: true,
          funded_count: true,
          expired_count: true,
          declined_count: true,
          all_count: true,
        },
      },
    }
    
    if (obj.sortBy && obj.sortDir) {
      objectForm.query.brokerGetQuotes.__args.sortBy = new EnumType(obj.sortBy.toUpperCase())
      objectForm.query.brokerGetQuotes.__args.sortDir = new EnumType(obj.sortDir)
    }

    if (obj.status) {
      objectForm.query.brokerGetQuotes.__args.status = new EnumType(obj.status)
    }

    if (obj.dealId) {
      objectForm.query.brokerGetQuotes.__args.dealId = obj.dealId
    }

    if (obj.filter) {
      objectForm.query.brokerGetQuotes.__args.filter = obj.filter
    }

    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async lenderGetQuotes(obj) {
    const objectForm = {
      query: {
        lenderGetQuotes: {
          __args: {
            perPage: obj.perPage, 
            page: obj.page,
            dealId: obj.dealId,
          },
          items: {
            id: true,
            deal_id: true,
            lender_id: true,
            address: true,
            lender_name: true,
            company_name: true,
            status: true,
            is_premium: true,
            rate_term: true,
            property_type: true,
            loan_programs: true,
            offer_amount: true,
            expire_at: true,
            interest_rate: true,
            origination_fee: true,
            rejected_reason: true,
            seen: true,
          },
          paginateInfo: {
            count: true,
            currentPage: true,
            firstItem: true,
            hasMorePages: true,
            lastItem: true,
            lastPage: true,
            perPage: true,
            total: true,
          },
        },
      },
    }
    
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async brokerAcceptQuote( id: number, reason: string) {
    const objectForm = {
      mutation: {
        brokerAcceptQuote: {
          __args: {
            id: id,
            reason: reason,
          },
          int: true,
          status: true,
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async brokerDeclineQuote(id: number, reason: string) {
    const objectForm = {
      mutation: {
        brokerDeclineQuote: {
          __args: {
            id: id,
            reason: reason,
          },
          int: true,
          status: true,
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async brokerUpdateAcceptSubStatusQuote(id: number, status: string) {
    const objectForm = {
      mutation: {
        brokerUpdateAcceptSubStatusQuote: {
          __args: {
            id: id,
            status: new EnumType(status),
          },
          int: true,
          status: true,
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },

  async brokerFundedQuote(id: number) {
    const objectForm = {
      mutation: {
        brokerFundedQuote: {
          __args: {
            id: id,
          },
          int: true,
          status: true,
        },
      },
    }
    const query: any = {
      query: jsonToGraphQLQuery(objectForm, { pretty: true }),
    }

    return await Api.post(URL_API, query)
  },
}
