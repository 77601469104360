import Quote from "@/services/graphql/Quote"
import ManageTable from "@/services/graphql/table/ManageTable"

export default {

  setMainLoading({ commit }, data) {
    commit("SET_LOADING", data)
  },

  async getBrokerQuotes({ commit }, obj) {
    commit("SET_NO_RESULTS", false)
    commit("SET_LOADING", true)

    commit("RESET_QUOTE_LIST")

    if (obj.sortBy && obj.sortDir) {
      commit("SET_QUOTES_SORT", {
        sortBy: obj.sortBy,
        sortDir: obj.sortDir,
        page: obj.page,
      })
    }

    return Quote.brokerGetQuotes(obj)
      .then(
        (response) => {
          commit("SET_QUOTE_LIST", response.data.data)

          if (
            response.data.data.brokerGetQuotes.items &&
            obj.filter &&
            response.data.data.brokerGetQuotes.items.length === 0
          ) {
            commit("SET_NO_RESULTS", true)
          }
        },
        (error) => {
          return Promise.reject(error)
        },
      )
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },

  async getLenderQuotes({ commit }, obj) {
    commit("SET_LOADING", true)
    commit("RESET_QUOTE_LIST")

    Quote.lenderGetQuotes(obj)
      .then(
        (response) => {
          commit("SET_LENDER_QUOTE_LIST", response.data.data)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
      .finally(() => {
        commit("SET_LOADING", false)
      })
  },

  async getQuoteStatusesCount({ commit }) {
    return Quote.getQuoteStatusesCount()
      .then(
        (response) => {
          commit("SET_QUOTE_STATUS_COUNT", response.data.data)
        },
        (error) => {
          return Promise.reject(error)
        },
      )
  },

  async brokerAcceptQuote({ commit }, obj) {
    return Quote.brokerAcceptQuote(obj.id, obj.reason).then(
      () => {
        commit("UPDATE_CAPABILITY_META", {
          id: obj.id,
          capabilities_meta: {
            can_open_message: true,
            can_set_accept_status: false,
            can_set_committed_status: true,
            can_set_funded_status: true,
            can_set_in_underwriting_status: true,
            can_set_reject_status: true,
            can_set_term_sheet_status: true,
            has_deal_accepted_quote: false,
            can_set_disable_reject_status: false,
          },
        })

        commit("UPDATE_QUOTE_STATUS", {
          id: obj.id,
          status: "ACCEPTED",
        })
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  async brokerDeclineQuote({ commit }, obj) {
    return Quote.brokerDeclineQuote(obj.id, obj.reason).then(
      () => {
        commit("UPDATE_CAPABILITY_META", {
          id: obj.id,
          capabilities_meta: {
            can_open_message: true,
            can_set_accept_status: false,
            can_set_committed_status: false,
            can_set_funded_status: false,
            can_set_in_underwriting_status: false,
            can_set_reject_status: false,
            can_set_term_sheet_status: false,
            has_deal_accepted_quote: false,
            can_set_disable_reject_status: true,
          },
        })

        commit("UPDATE_QUOTE_STATUS", {
          id: obj.id,
          status: "DECLINED",
        })
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  async brokerUpdateAcceptSubStatusQuote({ commit }, obj) {
    commit("UPDATE_QUOTE_STATUS", {
      id: obj.id,
      status: obj.status,
    })
    return Quote.brokerUpdateAcceptSubStatusQuote(obj.id, obj.status).then(
      () => {
        // commit("UPDATE_QUOTE_STATUS", {
        //   id: obj.id,
        //   status: obj.status,
        // })
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  async brokerFundedQuote({ commit }, obj) {
    return Quote.brokerFundedQuote(obj.id).then(
      () => {
        commit("UPDATE_QUOTE_STATUS", {
          id: obj.id,
          status: "FUNDED",
        })
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  async quoteManageTable({ commit }, obj) {
    commit("SET_QUOTE_TABLE_COLUMNS", obj)
    const res = await ManageTable.setManageTable(obj)
    obj.res = res
  },

  resetSorting({ commit }) {
    commit("RESET_SORTING")
  },
}
