import Api from '@/services/Api'
import { jsonToGraphQLQuery } from "json-to-graphql-query"

const URL_API = 'graphql'

export default {
    async getDealsToBeReviewed() {
        const query: any = {
            query: `query {
                dealsToBeReviewed {
                    id
                    days_remaining
                    location {
                        street_address
                        city
                        state
                        zip_code
                    }
                }
            }`,
        }
        return await Api.post(URL_API, query)
    },

    async getReviewSummary(userId) {
        const query: any = {
            query: `
            query {
                reviewSummary(
                    input: {
                        user_id: ${userId}
                    }
                ) {
                    count
                    summary {
                        category
                        stars
                    }
                    user {
                        role
                        id
                        first_name
                        last_name
                        email
                        status
                        company_id
                        profile_image
                    }
                }
            }`,
        }
        return await Api.post(URL_API, query)
    },

    async getReviews() {
        const query: any = {
            query: `
            query {
                reviews (
                    pagination: {
                        page: 1,
                        perPage: 15
                    },
                    input: {
                        context: {
                            filters: {
                            }
                        }
                    }
                ){
                    id
                    deal_id
                    text
                    is_pinned
                    is_hidden
                    has_expired
                    created_at
                    author {
                        id,
                        first_name
                    }
                    comments {
                        id
                        text
                        author {
                            id
                            first_name
                        }
                    }
                    ratings {
                        category
                        stars
                    }
                }
            }`,
        }
        return await Api.post(URL_API, query)
    },

    async getReviewsForUser(userId) {
        const query: any = {
            query: `
            query {
                reviews (
                    pagination: {
                        page: 1,
                        perPage: 15
                    },
                    input: {
                        context: {
                            filters: {
                                by_user: ${userId}
                            }
                        }
                    }
                ){
                    id
                    deal_id
                    text
                    is_pinned
                    is_hidden
                    has_expired
                    created_at
                    author {
                        id,
                        first_name
                    }
                    comments {
                        id
                        text
                        author {
                            id
                            first_name
                        }
                    }
                    ratings {
                        category
                        stars
                    }
                }
            }`,
        }
        return await Api.post(URL_API, query)
    },

    async getReviewsAboutUser(userId) {
        const query: any = {
            query: `
            query {
                reviews (
                    pagination: {
                        page: 1,
                        perPage: 15
                    },
                    input: {
                        context: {
                            filters: {
                                about_user: ${userId}
                            }
                        }
                    }
                ){
                    id
                    deal_id
                    text
                    is_pinned
                    is_hidden
                    has_expired
                    created_at
                    author {
                        id,
                        first_name
                    }
                    comments {
                        id
                        text
                        author {
                            id
                            first_name
                        }
                    }
                    ratings {
                        category
                        stars
                    }
                }
            }`,
        }
        return await Api.post(URL_API, query)
    },

    async getExpiredReviews() {
        const query: any = {
            query: `query {
                expiredReviews {
                    id
                    location {
                        street_address
                        city
                        state
                        zip_code
                    }
                }
            }`,
        }
        return await Api.post(URL_API, query)
    },

    async createReview(createForm) {
        const objectForm = {
            mutation: {
                createReview: {
                    __args: {
                        input: {
                            deal_id: createForm.deal_id,
                            text: createForm.text,
                            ratings: createForm.ratings,
                        },
                    },
                    id: true,
                    deal_id: true,
                    text: true,
                    is_hidden: true,
                    is_pinned: true,
                    has_expired: true,
                    created_at: true,
                    author: {
                        id: true,
                        first_name: true,
                    },
                    comments: {
                        id: true,
                        text: true,
                        author: {
                            id: true,
                            first_name: true,
                        },
                    },
                    ratings: {
                        stars: true,
                        category: true,
                    },
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },

    async deleteReviews(reviewIds) {
        const objectForm = {
            mutation: {
                hideReviews: {
                    __args: {
                        input: {
                            ids: reviewIds,
                        },
                    },
                    id: true,
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },

    async commentOnReview(reviewId, text) {
        const objectForm = {
            mutation: {
                comment: {
                    __args: {
                        input: {
                            review_id: reviewId,
                            text: text,
                        },
                    },
                    id: true,
                    text: true,
                    review: {
                        id: true,
                        text: true,
                    },
                    author: {
                        id: true,
                        first_name: true,
                    },
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },

    async hideReviews(reviewIds) {
        const objectForm = {
            mutation: {
                hideReviews: {
                    __args: {
                        input: {
                            ids: reviewIds,
                        },
                    },
                    id: true,
                    deal_id: true,
                    text: true,
                    is_hidden: true,
                    is_pinned: true,
                    has_expired: true,
                    created_at: true,
                    author: {
                        id: true,
                        first_name: true,
                    },
                    ratings: {
                        stars: true,
                        category: true,
                    },
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },

    async unhideReviews(reviewIds) {
        const objectForm = {
            mutation: {
                unHideReviews: {
                    __args: {
                        input: {
                            ids: reviewIds,
                        },
                    },
                    id: true,
                    deal_id: true,
                    text: true,
                    is_hidden: true,
                    is_pinned: true,
                    has_expired: true,
                    created_at: true,
                    author: {
                        id: true,
                        first_name: true,
                    },
                    ratings: {
                        stars: true,
                        category: true,
                    },
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },


    async pinReviews(reviewIds) {
        const objectForm = {
            mutation: {
                pinReviews: {
                    __args: {
                        input: {
                            ids: reviewIds,
                        },
                    },
                    id: true,
                    deal_id: true,
                    text: true,
                    is_hidden: true,
                    is_pinned: true,
                    has_expired: true,
                    created_at: true,
                    author: {
                        id: true,
                        first_name: true,
                    },
                    ratings: {
                        stars: true,
                        category: true,
                    },
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },


    async unpinReviews(reviewIds) {
        const objectForm = {
            mutation: {
                unpinReviews: {
                    __args: {
                        input: {
                            ids: reviewIds,
                        },
                    },
                    id: true,
                    deal_id: true,
                    text: true,
                    is_hidden: true,
                    is_pinned: true,
                    has_expired: true,
                    created_at: true,
                    author: {
                        id: true,
                        first_name: true,
                    },
                    ratings: {
                        stars: true,
                        category: true,
                    },
                },
            },
        }
        const query: any = {
            query: jsonToGraphQLQuery(objectForm, {pretty: true}),
        }

        return await Api.post(URL_API, query)
    },
}