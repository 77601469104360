export default {
  quoteList: (state: any) => {
    return {
      quoteList: state.quotes.list,
      paginateInfo: state.quotes.paginateInfo,
      tableColumns: state.quotes.tableColumns,
      lenderList: state.quotes.lenderList,
      quoteStatusesCount: state.quotes.quoteStatusesCount,
    }
  },
  quotesTabs: (state: any) => state.quotes.quoteStatusesCount,
  quotesLoading: (state: any) => state.quotes.loading,
  quotesSort: (state: any) => state.quotes.sort,
  quotesNoResults: (state: any) => state.quotes.noResults,
  quoteStatusesCount: (state: any) => state.quotes.quoteStatusesCount, 
}
