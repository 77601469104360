
    import Vue from 'vue'

    export default Vue.extend({
        props: {
            typeOfLoanCheck: {
                type: Function,
            },
        },

        data() {
            return {
                type_of_loans: [],
                hideCNBS: true,
            }
        },

        watch: {
            // type_of_loans() {
            //     this.isCheck();
            // }
        },

        methods: {
            isCheck() {
                this.$emit('typeOfLoanCheck')
            },
            getTypeOfLoans() {
                if (this.type_of_loans.length === 0)
                    return false
                
                return this.type_of_loans.map(ele => {
                    return parseInt(ele)
                })
            },
            setTypeOfLoans(arr) {
                this.type_of_loans = arr
            },
            showCNBS(flag) {
                this.hideCNBS = flag
            },
        },
    })
