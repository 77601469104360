import state from "./state"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

export default {
  namespaced: false,
  state: {
    ...state,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
