import Reviews from '@/services/graphql/Reviews'
import Vue from 'vue'

export default{
	namespaced: false,
	state: {
        reviewDeals: null,
        reviews: null,
        review: null,
        reviewSummary: null,
	},
	mutations: {
        SET_REVIEW_DEALS(state, data): void {
            state.reviewDeals = data
        },

        SET_REVIEWS(state, data): void{
            state.reviews = data
        },
        SET_REVIEW(state, data): void{
            state.review = data
        },
        SET_REVIEW_SUMMARY(state, data): void{
            state.reviewSummary = data
        },
        SET_COMMENT_ON_REVIEW(state, data): void{
            const reviewIndex = state.reviews.findIndex(r => r.id === data.reviewId)
            state.reviews[reviewIndex].comments.push(data.comment)
        },
        REMOVE_REVIEWS(state, reviewIds): void {
            reviewIds.forEach(reviewId => {
                const reviewIndex = state.reviews.findIndex(r => r.id === reviewId)
                if (reviewIndex >= 0) {
                    Vue.delete(state.reviews, reviewIndex)
                }
            })
        },
        UPDATE_REVIEWS(state, data): void {
            data.forEach(updatedReview => {
                const reviewIndex = state.reviews.findIndex(r => r.id === updatedReview.id)
                if (reviewIndex >= 0) {
                    Vue.set(state.reviews, reviewIndex, updatedReview)
                }
            })
        },
	},
	actions: {
        resetReviews({commit}) {
            commit('SET_REVIEWS', null)
            commit('SET_REVIEW', null)
            commit('SET_REVIEW_SUMMARY', null)
        },

        getReviewSummary({commit}, userId) {
            return Reviews.getReviewSummary(userId).then(
                response => {
                    if(response.data.data){
                        commit('SET_REVIEW_SUMMARY', response.data?.data?.reviewSummary)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },

        getDealsToBeReviewed({commit}) {
            return Reviews.getDealsToBeReviewed().then(
                response => {
                    if(response.data.data){
                        commit('SET_REVIEW_DEALS', response.data?.data?.dealsToBeReviewed)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },

        getReviews({commit}){
            return Reviews.getReviews().then(
                response => {
                    if(response.data.data){
                        commit('SET_REVIEWS', response.data?.data?.reviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },

        getReviewsForUser({commit}, userId){
            return Reviews.getReviewsForUser(userId).then(
                response => {
                    if(response.data.data){
                        commit('SET_REVIEWS', response.data?.data?.reviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },

        getReviewsAboutUser({commit}, userId){
            return Reviews.getReviewsAboutUser(userId).then(
                response => {
                    if(response.data.data){
                        commit('SET_REVIEWS', response.data?.data?.reviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },

        getExpiredReviews({commit}){
            return Reviews.getExpiredReviews().then(
                response => {
                    if(response.data.data){
                        commit('SET_REVIEWS', response.data?.data?.expiredReviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        createReview({commit}, createForm) {
            return Reviews.createReview(createForm).then(
                response => {
                    if(response?.data?.data){
                        console.log(response.data?.data)
                        commit('SET_REVIEW', response?.data?.data?.createReview)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        commentOnReview({commit}, { reviewId, message}) {
            return Reviews.commentOnReview(reviewId, message).then(
                response => {
                    if(response?.data?.data?.comment){
                        console.log(response.data?.data?.comment)
                        commit('SET_COMMENT_ON_REVIEW', {
                            reviewId: reviewId,
                            comment: response?.data?.data?.comment,
                        })
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        deleteReviews({commit}, reviewIds) {
            return Reviews.deleteReviews(reviewIds).then(
                response => {
                    if(response?.data?.data){
                        commit('REMOVE_REVIEWS', reviewIds)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        hideReviews({commit}, reviewIds) {
            return Reviews.hideReviews(reviewIds).then(
                response => {
                    if(response?.data?.data?.hideReviews){
                        commit('UPDATE_REVIEWS', response?.data?.data?.hideReviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        unhideReviews({commit}, reviewIds) {
            return Reviews.unhideReviews(reviewIds).then(
                response => {
                    if(response?.data?.data?.unHideReviews){
                        commit('UPDATE_REVIEWS', response?.data?.data?.unHideReviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        pinReviews({commit}, reviewIds) {
            return Reviews.pinReviews(reviewIds).then(
                response => {
                    console.log(response.data)
                    if (response?.data?.errors?.length) {
                        throw new Error('Pin limit reached')
                    }
                    if(response?.data?.data?.pinReviews){
                        commit('UPDATE_REVIEWS', response?.data?.data?.pinReviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
        unpinReviews({commit}, reviewIds) {
            return Reviews.unpinReviews(reviewIds).then(
                response => {
                    if(response?.data?.data?.unpinReviews){
                        commit('UPDATE_REVIEWS', response?.data?.data?.unpinReviews)
                    }
                    return Promise.resolve(response)
                },
                error => { return Promise.reject(error) },
            )
        },
    },
    getters: {
        pinnedReviews(state) {
            return state.reviews?.filter(review => review.is_pinned)
        },
        unpinnedReviews(state) {
            return state.reviews?.filter(review => !review.is_pinned)
        },
    },
}