import { useStorage } from "@vueuse/core"

interface AuthStorage {
  access_token?: string;
}

export const getHeaders = () => {
  const storage = useStorage<AuthStorage>("auth", {})

  const requiredHeaders: { [key: string]: string } = {}
  requiredHeaders['Content-Type'] = 'application/json'
  requiredHeaders['FL-PUBLIC-KEY'] = process.env.VUE_APP_AI_API_KEY
  requiredHeaders['authorization'] = 'Bearer ' + storage.value.access_token
  requiredHeaders['Accept'] = 'application/json'

  return requiredHeaders
}
