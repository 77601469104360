
import Vue from 'vue'
import SvgIcon from '@/components/base/SvgIcon/SvgIcon.vue'
import ClickOutside from 'vue-click-outside'

interface Data {
    inviteLender
    inviteBroker
    messageInviteLender
    messageInviteBroker
    successInviteLender
    successInviteBroker
    formSent: boolean
    success: boolean

    emails
    email: string
    errorEmail: boolean
    errorArray

    emailsBroker
    emailBroker: string
    errorEmailBroker: boolean
    showLoader: boolean
}

export default Vue.extend({

    components: {
        SvgIcon,
    },
    directives: {
        ClickOutside,
    },
    data(): Data {
        return {
            inviteLender: '',
            inviteBroker: '',
            messageInviteLender: '',
            messageInviteBroker: '',
            successInviteLender: false,
            successInviteBroker: false,
            formSent: false,
            success: false,

            email: "",
            emails: [],
            errorEmail: false,
            errorArray: [],

            emailBroker: "",
            emailsBroker: [],
            errorEmailBroker: false,
            showLoader: false,
        }
    },

    methods: {
        cancelForm(){
            this.$emit('closePopup')
        },
        removeEmail(index, flag) {
            (flag === 'lender') ? this.emails.splice(index, 1) : this.emailsBroker.splice(index, 1)
        },
        checkString(e, flag) {
            if(flag === 'lender') {
                this.errorEmail = false
                const emailVal = e.target.value
                const last = emailVal.substr(emailVal.length - 1)
                if(last === ';' || last === ',') {
                    const email = emailVal.slice(0, -1)
                    this.sendToCheck(email, 'lender')
                }
            } else {
                this.errorEmailBroker = false
                const emailVal = e.target.value
                const last = emailVal.substr(emailVal.length - 1)
                if(last === ';' || last === ',') {
                    const email = emailVal.slice(0, -1)
                    this.sendToCheck(email, 'broker')
                }
            }
        },
        sendToCheck(email, is) {
            const flag = this.checkEmail(email)
            if(is === 'lender') {
                if(flag) {
                    const check = this.checkIfExsist(email, 'lender')
                    if(check) {
                        this.emails.push(email)
                        this.errorEmail = false
                        this.email = ''
                    }
                } else {
                    this.errorEmail = true
                }
            } else {
                if(flag) {
                    const check = this.checkIfExsist(email, 'broker')
                    if(check) {
                        this.emailsBroker.push(email)
                        this.errorEmailBroker = false
                        this.emailBroker = ''
                    }
                } else {
                    this.errorEmailBroker = true
                }
            }
        },
        checkLongString(e, is) {
            this.errorArray = []
            const emailVal = e.target.value
            let commaSplice = emailVal.split(',')
            if(emailVal === '') {
                return
            }
            if(commaSplice.length === 0) {
                commaSplice = emailVal.split(';')
                if(commaSplice.length === 0) {
                    return
                }
            }

            commaSplice.forEach(email => {
                const flag = this.checkEmail(email, is)
                if(flag) {
                    const check = this.checkIfExsist(email, is)
                    if(check) {
                        (is === 'lender') ? this.emails.push(email) : this.emailsBroker.push(email)
                    } 
                } else {
                    this.setError({
                        email: email,
                        message: 'Email format',
                    })
                }
            });

            (is === 'lender') ? this.email = '' : this.emailBroker = ''
        },
        setError(obj){
            this.errorArray.push(obj)
        },
        checkIfExsist(email, is) {
            if(is === 'lender') {
                let flag = false
                this.emails.forEach(element => {
                    if(element === email) {
                        this.setError({
                            email: email,
                            message: 'We already have that email',
                        })
                        flag = true
                    }
                })
                if(flag) {
                    return false
                } else {
                    return true
                }
            } else {
                let flag = false
                this.emailsBroker.forEach(element => {
                    if(element === email) {
                        this.setError({
                            email: email,
                            message: 'We already have that email',
                        })
                        flag = true
                    }
                })
                if(flag) {
                    return false
                } else {
                    return true
                }
            }
        },
        checkEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(email).toLowerCase())
        },
        async sendInvite() {
            if(!this.showLoader) {
                this.showLoader = true
                let flag = true
                let flag1 = true
                if(this.emails.length > 0 || this.emailsBroker.length) {
                    await this.$store.dispatch('sendInvitationEmail', {inviteLender: this.emails, inviteBroker: this.emailsBroker}).then(response => {
                        if(response.data.data?.invitationEmail?.errorsResponseBroker?.length > 0) {
                            response.data.data.invitationEmail.errorsResponseBroker.forEach(obj => {
                                this.errorArray.push(obj)
                            })
                            flag = false
                        }
                        if(response.data.data?.invitationEmail?.errorsResponseLender?.length > 0) {
                            response.data.data.invitationEmail.errorsResponseLender.forEach(obj => {
                                this.errorArray.push(obj)
                            })
                            flag1 = false
                        }
                        this.emails = []
                        this.emailsBroker = []
                    })
                    if(flag && flag1) {
                        this.success = true
                        setTimeout(() => {
                            this.success = false
                        }, 5000)
                    }
                }
                this.showLoader = false
            }
        },
    },
})
