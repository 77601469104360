
    import Icon from './SvgIconGen.vue'

    export default {
        components: {
            Icon,
        },
        props: {
            iconLarge: {
                type: Boolean,
                default: false,
            },
            iconName: {
                type: String,
                required: true,
            },
        },
    }
