
    import Vue from 'vue'

    interface Data {
        menuItems,
    }

    export default Vue.extend({
        data(): Data {
            return {
                menuItems: [
                    {
                        url: '/terms-of-service',
                        label: 'Terms of Service',
                    },
					{
						url: '/privacy-policy',
						label: 'Privacy Policy',
                    },
                ],
            }
        },
        // if user logedin show header and footer
        computed: {
            loggedIn() {
                return this.$store.getters.getLoggedIn
            },
        },
    })
