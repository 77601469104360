export default {
  methods: {
     isTrialingOrActiveSubscription(subscriptionStatus) {
      const validStatuses = ['active', 'canceled', 'trialing']
      return validStatuses.includes(subscriptionStatus)
    },

    isActiveSubscription(subscriptionStatus) {
      // Note: 'canceled' is included because this only
      // happens when a subscription was canceled by not expired
      const validStatuses = ['active', 'canceled']
      return validStatuses.includes(subscriptionStatus)
    },

    isTrialingSubscription(subscriptionStatus) {
      return subscriptionStatus === 'trialing'
    },
  },
}
