export default {
  RESET_QUOTE_LIST(state) {
    state.quotes = {
      ...state.quotes,
      list: [],
      tableColumns: [],
      lenderList: [],
    }
  },

  SET_QUOTE_STATUS_COUNT(state, data) {
    state.quotes.quoteStatusesCount = data.quoteStatusesCount
  },

  SET_QUOTE_LIST(state, data) {
    const { quoteStatusesCount, brokerGetQuotes } = data

    const list = brokerGetQuotes.items.map((quote) => {
      return {
        ...quote,
        link: `/individual-quote-broker/${quote.deal_id}/${quote.lender_id}`,
      }
    })

    if (state.quotes.paginateInfo.currentPage !== brokerGetQuotes.paginateInfo.currentPage) {
      state.quotes.list = [...state.quotes.list, ...list]
    } else {
      state.quotes.list = list
    }

    state.quotes.paginateInfo = brokerGetQuotes.paginateInfo
    state.quotes.tableColumns = brokerGetQuotes.tableColumns
    state.quotes.lenderList = brokerGetQuotes.lenderList
    state.quotes.quoteStatusesCount = quoteStatusesCount
  },
  SET_LENDER_QUOTE_LIST(state, data) {
    const lenderGetQuotes = data.lenderGetQuotes

    const list = lenderGetQuotes.items.map((quote) => {
      return {
        ...quote,
        link: `/individual-quote/${quote.deal_id}/${quote.id}`,
      }
    })

    state.quotes = {
      ...state.quotes,
      list: list,
      paginateInfo: lenderGetQuotes.paginateInfo,
    }
  },
  SET_QUOTE_TABLE_COLUMNS(state, data) {
    state.quotes.tableColumns = data.arr
  },
  SET_LOADING(state, data) {
    state.quotes.loading = data
  },
  UPDATE_QUOTE_STATUS(state, data) {
    const quoteData = state.quotes.list.find((quote) => quote.id === data.id)

    if (!quoteData) return

    const dealID = quoteData.deal_id
    // const isStatusAcceptedOrFunded = data.status === "FUNDED" || data.status === "ACCEPTED"

    state.quotes.list = state.quotes.list.map((quote) => {
      if (quote.id === data.id) {
        quote.status = data.status
      }

      if (data.status === 'ACCEPTED' && quote.status === "ACCEPTED" && quote.deal_id === dealID && quote.id !== data.id) {
        quote.status = "DECLINED"
        quote.capabilities_meta = {
          can_open_message: true,
          can_set_accept_status: false,
          can_set_committed_status: false,
          can_set_funded_status: false,
          can_set_in_underwriting_status: false,
          can_set_reject_status: false,
          can_set_term_sheet_status: false,
          has_deal_accepted_quote: false,
          can_set_disable_reject_status: true,
        }
      }

      if (data.status === 'ACCEPTED' && quote.status === "QUOTED" && quote.deal_id === dealID && quote.id !== data.id) {
        quote.capabilities_meta = {
          has_broker_user_premium: true,
          can_set_accept_status: true,
          can_set_reject_status: true,
          can_set_funded_status: false,
          can_open_message: false,
          has_deal_accepted_quote: true,
          can_set_committed_status: false,
          can_set_term_sheet_status: false,
          can_set_in_underwriting_status: false,
          can_set_disable_reject_status: false,
        }
      }

      return quote
    })
  },
  UPDATE_CAPABILITY_META(state, data) {
    state.quotes.list = state.quotes.list.map((quote) => {
      if (quote.id === data.id) {
        quote.capabilities_meta = data.capabilities_meta
      }

      return quote
    })
  },
  SET_QUOTES_SORT(state, data) {
    state.quotes.sort = {
      ...data,
      sortCount: data.sortBy === state.quotes.sort.sortBy && data.page === state.quotes.paginateInfo.currentPage ? state.quotes.sort.sortCount + 1 : 0,
    }
  },
  RESET_SORTING(state) {
    state.quotes.sort = {
      sortBy: '',
      sortDir: '',
      sortCount: 0,
    }
  },
  SET_NO_RESULTS(state, data) {
    state.quotes.noResults = data
  },
}
