export default {
  chat: {
    rooms: [],
    room: [],
    messages: [],
    unseenMessages: {
      count: 0,
      rooms: [],
    },
    selectedRoom: {
      chat_id: null,
      room_id: null,
    },
    finishFirstMessage: false,
    isMessagesLoading: true,
    newMessage: null,
    playNotificationSound: false,
    dealLastUnseenMessage: null,
    scrollToBottom: 0,
  },
  chatCountNewMsg: null,
  currentChatId: null,
  lenderOpenClosedChat: null,
}
