import Chat from "@/services/graphql/Chat"

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async initialChatLender() {
    // const res = await Chat.initialChatLender(dealId)
    // commit("SET_CHAT", res.data.data.chatLender)
    // if (res.data.data.chatLender) commit("SET_CURRENT_CHAT", res.data.data.chatLender.room_id)
    // return res
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async initialChatBroker() {
    // const res = await Chat.initialChatBroker(dealId)
    // commit("SET_CHAT", res.data.data.chatBroker)
    // commit("SET_CHAT_NEW_MSG_COUNT", res.data.data.chatBroker.rooms)
    // return res
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendMessageLender({ commit }, obj) {
    const fakeIdMsg = Math.trunc(Math.random() * 20651161)
    const fakeData = {
      room_id: obj.room_id,
      chat: {
        id: fakeIdMsg,
        message: obj.msg,
        role: "lender",
        seen: false,
        time: "",
        forbidden_msg: obj.forbidden_msg,
      },
    }
    commit("SET_NEW_MESSAGE_LENDER", fakeData)

    Chat.sendMessageLender(obj).then(
      (response) => {
        commit("SET_NEW_MESSAGE_LENDER_AFTER_FAKE", {
          data: response.data.data.chatSendMessageLender,
          fake: fakeIdMsg,
        })
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendMessageBroker({ commit }, obj) {
    const fakeIdMsg = Math.trunc(Math.random() * 20651161)
    const fakeData = {
      room_id: obj.room_id,
      chat: {
        id: fakeIdMsg,
        message: obj.msg,
        role: "broker",
        seen: false,
        time: "",
        forbidden_msg: obj.forbidden_msg,
      },
    }
    commit("SET_NEW_MESSAGE_BROKER", fakeData)

    Chat.sendMessageBroker(obj).then(
      (response) => {
        commit("SET_NEW_MESSAGE_BROKER_AFTER_FAKE", {
          data: response.data.data.chatSendMessageBroker,
          fake: fakeIdMsg,
        })
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  seenMessages({ commit }, obj) {
    const res = Chat.seenMessages(obj)
    if (obj.index !== -1) {
      commit("CHANGE_SEEN_MESSAGES", obj)
      // commit("SET_CHAT_NEW_MSG_COUNT", [])
    } else {
      commit("CHANGE_SEEN_MESSAGES_LENDER", obj.arr)
    }
    return res
  },

  async setMsgFromPusher({ state, commit, dispatch }, obj) {
    if (obj.chat.forbidden_msg) {
      return
    }

    dispatch("playNotificationSound", true)

    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, "0") // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0")
    const hours = String(today.getHours()).padStart(2, "0")
    const minutes = String(today.getMinutes()).padStart(2, "0")
    const seconds = String(today.getSeconds()).padStart(2, "0")

    const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    obj.chat.time = time

    if (
      state?.chat?.rooms?.items &&
      (window.location.pathname === "/broker-messages" || window.location.pathname === "/lender-messages")
    ) {
      const index = state?.chat?.rooms?.items.findIndex((item) => item.chat_id === obj.chat_id)

      if (index === -1) {
        await dispatch("getChatRooms", {
          page: 1,
          perPage: 20,
        })
      }
    }

    if (obj.chat.role === "broker") {
      commit("SET_NEW_MESSAGE_TABLE_LENDER", obj.deal_id)
    } else {
      commit("SET_NEW_MESSAGE_TABLE_BROKER", obj.deal_id)
    }

    commit("SET_NEW_MESSAGE_FROM_PUSHER", obj)

    commit("INCREMENT_UNSEEN_MESSAGES", obj)
  },

  firstMessageSentLender({ commit }, obj) {
    commit("SET_NEW_FIRST_MESSAGE_BROKER", obj)
    commit("SET_NEW_MESSAGE_TABLE_BROKER", obj.deal_id)
    // commit("SET_CHAT_NEW_MSG_COUNT", [])
  },

  currentChatId({ commit }, id) {
    commit("SET_CURRENT_CHAT", id)
  },

  resetChat({ commit }) {
    commit("RESET_CHAT")
  },

  setBrokerFirstChat({ commit }, obj) {
    commit("SET_BROKER_FIRST_CHAT", obj)
  },

  isChatOpen({ commit }, data) {
    commit("IS_CHAT_OPENED", data)
  },

  getZoomUrl(store, options) {
    return Chat.getZoomUrl(options).then((response) => {
      return Promise.resolve(response)
    })
  },

  sendZoomLinks({ dispatch }, { start_url, join_url, room_id, deal_id, user }) {
    if (user.role === "lender") {
      dispatch("sendMessageLender", {
        msg: `startzoomlink-${start_url}`,
        room_id,
        deal_id,
        forbidden_msg: false,
      })

      dispatch("sendMessageLender", {
        msg: `joinzoomlink-${join_url}`,
        room_id,
        deal_id,
        forbidden_msg: false,
      })
    } else {
      dispatch("sendMessageBroker", {
        msg: `startzoomlink-${start_url}`,
        room_id,
        deal_id,
        forbidden_msg: false,
        lender_id: 0,
      })

      dispatch("sendMessageBroker", {
        msg: `joinzoomlink-${join_url}`,
        room_id,
        deal_id,
        forbidden_msg: false,
        lender_id: 0,
      })
    }
  },

  async getChatRooms({ commit }, obj: { page: number; perPage: number; deal_id?: number }) {
    if (obj.page === 1) {
      commit("RESET_ROOMS")
    }

    const res = await Chat.getChatRooms(obj)

    commit("SET_ROOMS", res.data.data.chatRooms)

    return res
  },

  async getChatRoomMessages({ commit }, obj: { room_id: number; page: number; perPage: number }) {
    if (obj.page === 1) {
      commit("RESET_MESSAGES")
      commit("IS_MESSAGES_LOADING", true)
    }

    const res = await Chat.getChatRoomMessages(obj)

    commit("SET_ROOM_MESSAGES", res.data.data.chatRoomMessages)

    return res
  },

  async sendMessage({ commit, dispatch, state }, obj) {
    const role = obj.role

    const firstMessage = obj.first_message
    delete obj.first_message

    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, "0") // Months are zero-based
    const day = String(today.getDate()).padStart(2, "0")
    const hours = String(today.getHours()).padStart(2, "0")
    const minutes = String(today.getMinutes()).padStart(2, "0")
    const seconds = String(today.getSeconds()).padStart(2, "0")

    const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

    const fakeIdMsg = Math.trunc(Math.random() * 20651161)
    const fakeData = {
      id: fakeIdMsg,
      name: obj.name,
      message: obj.msg,
      role: role,
      time: time,
      seen: false,
      forbidden_msg: obj.forbidden_msg,
      fake: true,
    }

    if (!firstMessage) {
      commit("SET_MESSAGE_ON_ROOM", fakeData)
    }

    let request: {
      msg: string
      forbidden_msg: boolean
      room_id: number
      deal_id: number
      lender_id?: number
    } = {
      msg: obj.msg,
      forbidden_msg: obj.forbidden_msg,
      room_id: obj.room_id,
      deal_id: obj.deal_id,
    }

    if (role === "broker") {
      request = {
        ...request,
        lender_id: obj.lender_id,
      }
    }

    const sendMessage = role === "broker" ? Chat.sendMessageBroker(request) : Chat.sendMessageLender(request)

    if (!firstMessage) {
      commit("UPDATE_MESSAGE_ON_ROOM", fakeData)
    }

    state.chat.scrollToBottom = state.chat.scrollToBottom + 1

    sendMessage.then(
      async (response) => {
        const data =
          role === "broker" ? response.data.data.chatSendMessageBroker : response.data.data.chatSendMessageLender

        if (firstMessage) {
          await dispatch("getChatRooms", {
            deal_id: obj.deal_id,
            page: 1,
            perPage: 20,
          })

          await dispatch("getChatRoomMessages", {
            room_id: data.chat_id,
            page: 1,
            perPage: 20,
          })

          commit("SELECT_ROOM", {
            room_id: data.room_id,
            chat_id: data.chat_id,
          })

          commit("FINISH_FIRST_MESSAGE")
        }
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  },

  async getUnseenMessages({ commit }) {
    const res = await Chat.getUnseenMessages()

    commit("SET_UNSEEN_MESSAGES", res.data.data.unseenMessages)

    return res
  },

  async getDealLastUnseenRoom({ commit }, obj) {
    commit("SET_LAST_UNSEEN_ROOM", null)

    const res = await Chat.getDealLastUnseenRoom(obj)

    commit("SET_LAST_UNSEEN_ROOM", res.data.data.lastUnseenRoom)

    return res
  },

  unreadMessage({ commit }, data) {
    commit("UNSEEN_MESSAGES_DECREMENT", data.room_id)
    commit("SEEN_MESSAGE_DEAL_TABLE_LENDER", {
      id: data.deal_id,
    })
    commit("SEEN_MESSAGE_DEAL_TABLE_BROKER", {
      id: data.deal_id,
    })
  },
  playNotificationSound({ commit }, data) {
    commit("SET_PLAY_NOTIFICATION_SOUND", data)
  },
}
